import congressvenue_banner from '../../resource/images/internationalhotel.png';
import congressvenue_pic1 from '../../resource/images/taxi.png';

const pageData = {
    banner: congressvenue_banner,
    imgs: [congressvenue_pic1],
    listData: [
        {
            content: '<p><strong class="first-letter">I</strong>n China, many taxi drivers do not speak English. To assist with your travel, we have prepared a card (image file) with the conference venue address in Chinese. We recommend printing it in advance and showing it to the driver to help you get to the conference hotel. </p>'
        },
        {
            content: '<p>Please show the chinese character image to the taxi driver. The image content is:</p>'
        },
        {
            content: '<p>Destination: Beijing International Hotel, No. 9 Jianguomen Inner St., Dongcheng District, Beijing </p>'
        },
        {
            content: `
        <p>If you would like to have more about the transportation information related to this trip, you can refer to the PDF file below:</p>
        <p><a href="https://www.iacr2024.com/pdf/transportation.pdf" target="_blank">Transportation Information PDF</a></p>
      `
        },
    ]
};

export default pageData;
